import React, {Component} from 'react';
import {Navbar, Nav, Container} from 'react-bootstrap';
import {Link, NavLink} from 'react-router-dom';
import './NavBar.css'

interface Props {

}

interface State {

}

export default class MyNavbar extends Component<Props, State> {
    state = {}

    render() {
        return (
            <Navbar fixed="top" expand="sm" style={{marginTop: "30px"}}>
                <Container>
                    <Navbar.Brand>
                        <Link to="/">
                            <img
                                alt=""
                                src={require('../Kosa_color.png')}
                                height="60"
                            />{' '}
                        </Link>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ml-auto">
                            <Link to="/about" className="navItem">About</Link>
                            <Link to="/survey" className="navItem">Survey</Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        )
    }
}
 