import React, {useState} from 'react';
import * as typeformEmbed from '@typeform/embed'
import './Survey.css'

const Survey = () => {
  const [text, setText] = useState('Please take a quick survey to help us better understand credit card habits.')

  typeformEmbed.makePopup(
    'https://liam349935.typeform.com/to/J4wxlF',
    {
      mode: 'popup',
      autoOpen: true,
      autoClose: 3,
      hideScrollbars: true,
      onSubmit: function () {
        setText('Thanks for helping us out!')
      },
    }
  )

  return (
    <div>
      <header className="App-header">
        <p id='#surveyEmbed' className="surveyTitle">
          {text}
        </p>
      </header>
    </div>
  );
}

export default Survey;