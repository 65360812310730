import React from 'react';
import {Switch, Route} from 'react-router-dom';
import ReactGA from 'react-ga';
import './App.css'

import Home from './Components/Home';
import About from './Components/About';
import Survey from './Components/Survey';
import NavBar from './Components/NavBar';
import history from './Components/History'
import {Container} from "react-bootstrap";

ReactGA.initialize('UA-167309371-1')

history.listen(location => {
    ReactGA.set({page: location.pathname});
    ReactGA.pageview(location.pathname);
});

const App = () => (
    <div className='app'>
        <Container fluid className="background-gradient">
            <NavBar/>
            <Main/>
        </Container>
    </div>
);

const Main = () => (
    <Switch>
        <Route exact path='/' component={Home}></Route>
        <Route exact path='/about' component={About}></Route>
        <Route exact path='/survey' component={Survey}></Route>
    </Switch>
);

export default App;
