import React from "react";
import {Button, Form} from "react-bootstrap";
import './MyForm.css';
import {API, graphqlOperation} from 'aws-amplify';
import {createContact} from "../graphql/mutations";

class MyForm extends React.Component {
    state = {email: '', message: ''};

    subscribeUser = async email => {
        let apiName = 'emailLambda';
        let path = '/emails';
        let myInit = {
            body: { email }
        }
        try {
            const res = await API.post(apiName, path, myInit);
            console.log(res);
        } catch (err) {
            console.log(err)
        }
    }
        // event.preventDefault();
        // const userEmail = {email: this.state.email};
        // console.log("reached here");
        // try {
        //     await API.graphql(graphqlOperation(createContact, {input: userEmail}));
        //     // this.setState({ message: res })
        // } catch (e) {
        //     console.log(e);
    //     }
    // }

    render() {
        return (
            <Form onSubmit={this.subscribeUser}>
                <Form.Row className={"rowStyle"}>
                    <Form.Group controlId={"formBasicName"}>
                        <Form.Control type="email" name="EMAIL" placeholder="Email" value={this.state.email}
                                      size='lg'
                                      className="inputForm"
                                      required={true}
                                      onChange={event => this.setState({email: event.target.value})}/>
                    </Form.Group>
                    <div className="clear">
                        <Button type="submit" value="Subscribe" name="subscribe"
                                size='lg'
                                className="submitButton"
                                id="mc-embedded-subscribe"
                                block>
                            Submit
                        </Button>
                    </div>
                </Form.Row>
            </Form>
        );
    }
}

export default MyForm;