import React from 'react';
import './About.css'

const About = () => {
    return (
        <div>
            <header className="App-header">
                <p className="aboutTitle">
                    About page!
                </p>
            </header>
        </div>
    );
}

export default About;