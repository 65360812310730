import React from 'react';
import './Home.css';
import 'bootstrap/dist/css/bootstrap.css';
import MyForm from './MyForm';

const Home = () => {
    return (
        <div>
            <header className="App-header">
                <img src={require('../Kosa_logo.png')} className="App-logo" alt={"logo"}/>
                <p className="siteInProgress">
                    Site in Progress...
                </p>
                <p className="signUp">
                    Want to Receive Updates?
                </p>
                <MyForm/>
            </header>
        </div>
    );
}

export default Home;